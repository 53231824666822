
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';

export default defineComponent({
  name: 'course registration',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  props: ['trainee_name', 'trainee_mobile', 'trainee_email', 'otp_match'],
  data() {
    return {
      batch: {
        family_own_home: '',
        family_own_land: '',
        course: '',
        location: '',
        training_partner: '',
        training_institute: '',
      },
      details: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      subPermanentDistrict: [],
      subHomePermanentDistrict: [],
      empDistricts: [],
      empHomeDistricts: [],
      trainee: {
        nid: '',
        name: '',
        father_name: '',
        mother_name: '',
        present_upazilla: '',
        post_code: '',
        permanent_upazilla: '',
        trainer_image: '',
        passing_year: '',
        ref_id: '',
        mobile: '',
        alt_mobile: '',
        email: '',
        gender: '',
        religion: '',
        date_of_birth: '',
        home_district: '',
        home_upazilla: '',
        ethnic: '',
        hightest_class: '',
        experience_year: '',
        monthly_income: '',
        is_employee: '',
        is_physically: '',
        remarks: '',
        present_address: '',
        present_districts: '',
        permanent_address: '',
        permanent_districts: '',
        mother_edu: '',
        mother_occ: '',
        father_edu: '',
        father_occ: '',
        family_income: '',
        sibling_no: '',
        course_sector: '',
        course: '',
        location: '',
        training_partner: '',
        training_institute: '',
        bank_account_type: '',
        moblie_bank: '',
        branchName: '',
        routingNo: '',
        account_no: '',
        confirmaccount_no: '',
        family_own_home: '',
        family_own_land: '',
        status: '0',
      },

      obj: {
        entity_type_id: '',
        id: '',
      },
      entity: {
        entity_type_id: '',
        entity_type_role_id: '',
        user_id: '',
        entity_info_id: '',
        institute_info_id: '',
      },
      entityId: '',
      entityRoles: [],
      institutes: [],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    // await this.getIndustry();
    await this.getEntityInfos();
    // await this.getDivision();
    await this.getDistrictForEmp();
  },
  methods: {
    async formSubmit() {
      //let data = new FormData();
      let trainee = {};
      Object.keys(this.trainee).forEach((key) => {
        if (this.trainee[key] != '') trainee[key] = this.trainee[key];
      });

      let data = {
        trainee: trainee,
      };
      this.loading = true;
      await ApiService.post('trainee/save', data)
        .then((response) => {
          this.loading = false;
          if (response.status == 200) {
            if (response.data.status == 'error') {
              this.loading = false;
              Swal.fire({
                title: 'Error!',
                html: response.data.message,
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: 'Close',
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              });
            } else {
              Swal.fire({
                text: response.data,
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              }).then(() => {
                this.trainee = {
                  nid: '',
                  name: '',
                  father_name: '',
                  mother_name: '',
                  present_upazilla: '',
                  post_code: '',
                  permanent_upazilla: '',
                  trainer_image: '',
                  passing_year: '',
                  ref_id: '',
                  mobile: '',
                  alt_mobile: '',
                  email: '',
                  gender: '',
                  religion: '',
                  date_of_birth: '',
                  home_district: '',
                  home_upazilla: '',
                  ethnic: '',
                  hightest_class: '',
                  experience_year: '',
                  monthly_income: '',
                  is_employee: '',
                  is_physically: '',
                  remarks: '',
                  present_address: '',
                  present_districts: '',
                  permanent_address: '',
                  permanent_districts: '',
                  mother_edu: '',
                  mother_occ: '',
                  father_edu: '',
                  father_occ: '',
                  family_income: '',
                  sibling_no: '',
                  course_sector: '',
                  course: '',
                  location: '',
                  training_partner: '',
                  training_institute: '',
                  bank_account_type: '',
                  moblie_bank: '',
                  branchName: '',
                  routingNo: '',
                  account_no: '',
                  confirmaccount_no: '',
                  family_own_home: '',
                  family_own_land: '',
                  status: '0',
                };
                this.$router.push('/sign-up');
              });
            }
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: 'Please check all the required field',
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: 'Unknown error',
            html: response.data.error,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
        });
    },

    async getDistrictForEmp() {
      await ApiService.get('geo/districts')
        .then((response) => {
          this.empDistricts = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getSubDistrict() {
      await ApiService.get(
        'geo/upazilas?district=' + this.trainee.present_districts
      )
        .then((response) => {
          this.subDistricts = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getPermanentSubDistrict() {
      await ApiService.get(
        'geo/upazilas?district=' + this.trainee.permanent_districts
      )
        .then((response) => {
          this.subPermanentDistrict = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getHomeDistrict() {
      await ApiService.get(
        'geo/upazilas?district=' + this.trainee.home_district
      )
        .then((response) => {
          this.subHomePermanentDistrict = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityInfos() {
      await ApiService.get('entity/infos')
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async training_institute() {
      await ApiService.get('entity/training_institutes/' + this.entityId)
        .then((response) => {
          this.institutes = response.data;
          this.obj = {
            entity_type_id: '',
            id: '',
          };
          this.entity.entity_type_role_id = '';
          this.entityRoles = [];
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() {
    const TrainerSchema = Yup.object().shape({
      email: Yup.string()
        .email('Must be valid email')
        .required()
        .label('Email'),
      //presentDistrict: Yup.string().required().label("Present District"),
      name: Yup.string().required().label('Name'),
      nid: Yup.string().required().label('NID No'),
      father_name: Yup.string().required().label('Father Name'),
      mother_name: Yup.string().required().label('Mother Name'),
      trainer_image: Yup.string().required().label('Trainer Photo'),
      religion: Yup.string().required().label('Religion'),
      gender: Yup.string().required().label('gender'),
      mobile: Yup.number().required().min(11).label('Mobile'),
      ref_id: Yup.number().required().label('Reference Id'),
      mother_edu: Yup.string().required().label("Mother's Education Level"),
      mother_occ: Yup.string().required().label("Mother's Occupation"),

      branchName: Yup.string().required().label('Branch Name'),
      routingNo: Yup.string().required().label('Routing No'),
      account_no: Yup.string().required().label('Account Number'),
      present_districts: Yup.string().required().label('Present District'),
      course: Yup.string().required().label('Course'),
      location: Yup.string().required().label('Location'),
      course_sector: Yup.string().required().label('Sector Name'),
      training_partner: Yup.string().required().label('Training Partner'),
      training_institute: Yup.string().required().label('Training Institute'),
      permanent_districts: Yup.string().required().label('Permanent District'),
      permanent_upazilla: Yup.string().required().label('Permanent Upazilla'),
      present_upazilla: Yup.string().required().label('Present Upazilla'),
      is_employee: Yup.string().required().label('Is Employee'),
      is_physically: Yup.string().required().label('Is Physically'),
      remarks: Yup.string().required().label('Remarks'),
      passing_year: Yup.string().required().label('Passing Year'),
      family_own_home: Yup.string().required().label('Family Owned Home'),
      family_own_land: Yup.string().required().label('Family Owned Land'),
      bank_account_type: Yup.string().required().label('Bank Account Type'),
      moblie_bank: Yup.string().required().label('Bank Name'),
      confirmaccount_no: Yup.string()
        .required()
        .label('Confirm Account Number'),
      // permanent_districts: Yup.string().required().label("Permanent District"),

      password: Yup.string()
        .required()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
        )
        .label('Password'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], "Password didn't match!")
        .label('Confirm Password'),
    });
    return {
      TrainerSchema,
    };
  },
});
